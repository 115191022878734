.rightMenu {
  .rightMenu-container {
    position: fixed;
    right: 0;
    top: 70px;
    z-index: 1001;
    width: 380px;
    height: 94vh;
    background-color: #f5f6fa;
    padding: 10px 20px;
    overflow-y: auto;

    &.fixed-top {
      top: 0;
    }

    .rightMenu-header {
      font: var(--size-xl) var(--roboto-medium);
      color: #2c3d50;
      margin-bottom: 20px;

      .rightMenu-close-icon {
        position: absolute;
        right: 15px;
        top: 13px;
        cursor: pointer;
      }
    }

    .rightMenuData {
      .rightMenuData-title {
        font: var(--size-base) var(--roboto-medium);
        color: #2c3d50;
      }
      .rightMenuData-tag {
        font-size: 12px;
        color: #999999;

        .rightMenu-subhead-icon {
          height: 5px;
          margin-right: 5px;
          margin-bottom: 3px;
        }
      }

      .rightMenuData-desc {
        font: var(--size-s) var(--roboto-regular);
        // margin-bottom: 20px;
      }

      .border-bottom {
        border-bottom: 1px solid #e6e6e6;
        margin: 20px 0;
      }
    }

    .rightMenu-footer {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 380px;
      background-color: #f5f6fa;
      padding: 20px 10px;
      box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
      display: flex;

      .rightMenu-link {
        font: var(--size-base) var(--roboto-medium);
        text-decoration: none;
        color: #1382c5;
        margin-right: 4px;
      }
      img {
        width: 12px;
        height: 12px;
        margin-top: 2px;
      }
    }
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
