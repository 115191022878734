.ed-confirmation-dialog {
  z-index: 15000 !important;
  svg {
    width: 20px !important;
  }
  .MuiDialog-paper {
    padding: 20px;
    background: var(--white);
    min-width: 485px;
  }
  .MuiTypography-h6 {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      color: var(--group-header);
      font: var(--size-xl) var(--roboto-regular);
      margin-left: 15px;
    }
  }
  .MuiDialogContentText-root {
    font: var(--size-base) var(--roboto-regular);
    margin-top: 15px;
    color: var(--group-header);
    line-height: 24px;
    span {
      display: block;
      margin-bottom: 25px;
    }
  }
  &.connector {
    .MuiDialogContentText-root {
      span {
        line-height: 25px;
        margin-bottom: 0;
      }
    }
  }
  .MuiDialogActions-root {
    .btn {
      &--ok {
        &:first-child {
          border: none;
        }
        &:only-child {
          border: 1px solid var(--white);
        }
      }
    }
  }
  &.no-description {
    .MuiDialogTitle-root {
      // padding: 16px 24px 0;
      .MuiTypography-h6 {
        img {
          width: 40px;
          height: 40px;
        }
        span {
          font: var(--size-base) var(--roboto-regular);
        }
      }
    }
  }
  &.audit-error {
    .MuiDialogContent-root {
      padding-left: 0 !important;
      overflow-y: hidden !important;
    }
    .MuiDialogContentText-root {
      span {
        text-align: center;
      }
    }
  }
}

// ToDO removed when all module are moved to react
.confirmation-dialog {
  &.leaveRoute {
    .MuiPaper-rounded {
      border-radius: 0;
    }
    .MuiDialog-paper {
      padding: 0;
      max-width: 376px;
      background: #e7edef;
    }
    .MuiDialogTitle-root {
      background: url('/images/zdb-information.png') rgb(64, 93, 117);
      background-repeat: no-repeat;
      background-position: left;
      background-position-x: 15px;
    }
    .MuiTypography-h6 {
      justify-content: center;
      span {
        font-size: 16px;
      }
      img {
        display: none;
      }
    }
    .MuiDialogContent-root {
      padding: 24px 24px 0;
      .MuiDialogContentText-root {
        span {
          margin-bottom: 0;
          color: #44484a;
        }
      }
    }
    .MuiDialogActions-root {
      .btn {
        &--ok {
          background: #379fd5 !important;
        }
      }
    }
  }
}
.ml-0 {
  margin-left: 0 !important;
}
.lastActiveProject {
  .btn--cancel3 {
    background-color: var(--white);
    border: 1px solid #e8c21d;
    color: #e8c21d;
    &:focus,
    &:active,
    &:hover {
      border: 1px solid #e8c21d;
      background-color: var(--white);
      color: #e8c21d;
    }
  }
  .btn--save2 {
    color: var(--white);
    background-color: #e8c21d;
    border: 1px solid #e8c21d;
    &:focus,
    &:active,
    &:hover {
      border: 1px solid #e8c21d;
      background-color: #e8c21d;
      color: var(--white);
    }
  }
  .dialog-close {
    width: 12px !important;
    position: absolute;
    right: 30px;
  }
}
