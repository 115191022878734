/*************** Default declarations of media queries **********/

@media handheld, screen and (max-device-width: 480px) {
  body {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 1024px) {
  body {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  body {
    width: 100%;
  }
}
@media print {
  body {
    width: 100%;
  }
}

/************ DEFINING FONTS **************/
body {
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/********************* STYLES FOR PLACEHOLDERS *****************/

::-webkit-input-placeholder {
  color: #dcdcdc;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}
:-moz-placeholder {
  /* Firefox 18- */

  color: #dcdcdc;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}
::-moz-placeholder {
  /* Firefox 19+ */

  color: #dcdcdc;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}
:-ms-input-placeholder {
  color: #dcdcdc;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

/********************* LOGIN PAGE STYLES ************/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

li:focus {
  color: rgb(36, 61, 81);
}
/**************** CIRCLES AND OVALS ***************/
.wrapper:hover {
  -moz-box-shadow: 1px 1px 5px #999;
  -webkit-box-shadow: 1px 1px 5px #999;
  box-shadow: 1px 1px 5px #999;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #243b4f;
  border-color: #428bca;
}
.error {
  color: #f97777;
  padding: 0px;
  height: auto;
  text-align: left;
}
/*************************  new classes  ******************/
.modal-dialog {
  z-index: 1050 !important;
}
/************************ Ticket Map ************************/
.popover {
  max-width: 600px !important;
}
.mapboxgl-canvas {
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
}

.form-control {
  height: 42px;
  background-color: #fcfcfc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea {
  border-color: #ccc;
}
input[type="file"] {
  display: none !important;
}
.mouse-pointer {
  cursor: pointer !important;
}
.loading-image {
  background-image: url(../images/loadBoxes.gif);
  background-repeat: no-repeat;
  height: 75px;
  width: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 7000;
}
.loading {
  margin-left: 45%;
}

.sd-new-proj {
  padding-left: 0px;
}
.ed-new-proj {
  padding-left: 0px;
}
.sd-new-proj p {
  margin-bottom: 2px;
}
.ed-new-proj p {
  margin-bottom: 2px;
}
.form-control {
  height: 42px;
  background-color: #fcfcfc;
}

.newProj-overflow {
  overflow: hidden;
  padding-top: 4px;
  margin-top: 15px;
}
/***************************  close ****************/
.close {
  font-size: 40px !important;
}

.body_images_try {
  max-width: 100%;
  max-height: 100%;
  height: 230px;
  width: 270px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 15px;
}
.project_try {
  font-size: 20px;
  color: #243b4f;
  padding-left: 7%;
  margin-bottom: 20px;
  margin-top: 5px;
}
@media screen and (min-width: 1071px) and (max-width: 1200px) {
  .ed-proj-sub-container {
    margin-left: 3.5%;
  }
}
