.walkthrough {
  background-color: var(--white);
  padding: 3rem 0;
  font-family: var(--roboto-regular);

  & h2 {
    font-family: var(--roboto-medium);
    color: var(--ed-dark_blue);
  }

  & .btn-edblue {
    color: var(--white);
    background-color: var(--ed-dark_blue);
    padding: 1px 8px;
  }

  .walkthrough-steps {
    padding: 25px 0;
    display: flex;
    flex-direction: column;

    & .walkthrough-step {
      display: flex;

      & div:first-child,
      div:nth-child(2),
      div:nth-child(3) {
        padding: 5px 10px;
      }

      & .illustration-image {
        width: 65px;
      }

      &.step-3 {
        & ul {
          padding-left: 25px;

          & li {
            line-height: 1.5;

            &::marker {
              font-size: 18px;
            }
          }
        }
      }
    }

    & span {
      font-weight: 900;
      font-family: var(--roboto-bold);

      &.step {
        color: var(--ed-dark_blue);
        font-size: var(--size-base);
        white-space: nowrap;
      }

      &.step-heading {
        color: var(--ed-blue);
        font-size: var(--size-base);
      }
    }

    button {
      padding: 1px 8px;
    }
  }
}

.walkthrough-lib{
  @extend .walkthrough;
  margin-top: 70px;

  .walkthrough-steps {
    @extend .walkthrough-steps;

    & .walkthrough-step {
      @extend .walkthrough-step;
    }
  }
}