.app-guide-component {
  font-family: var(--roboto-regular);
  .close-popup {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    padding: 5px 7px;
    &:hover {
      background-color: #f6f6f6;
    }
    img {
      height: 15px;
      width: 15px;
    }
  }

  .app-guide-container {
    min-height: 520px;
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: white;
    padding: 60px 60px 35px;
    border-radius: 50px;
  }

  h2 {
    color: #2c3d50;
    font-size: 16px;
    margin-top: 0;
  }

  p {
    color: #2c3d50;
    font-size: 14px;
  }

  .app-guide-1 {
    min-height: 300px;
    margin-bottom: 15px;

    & .tool-img {
      width: 60px;
      height: 65px;
      display: block;
      margin: 0 auto;
    }

    h2 {
      margin-top: 40px;
    }

    p {
      margin-top: 20px;
    }
  }

  .app-guide-2,
  .app-guide-3 {
    min-height: 300px;
    margin-bottom: 15px;

    & .edit-img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
    h2 {
      margin-top: 20px;
    }
  }

  .highlight {
    display: flex;

    .screen1,
    .screen2,
    .screen3 {
      flex: 0 0 31%;
      margin-right: 10px;
      height: 4px;
    }
  }

  .appGuide_footer {
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .prev,
    .next,
    .finish {
      border: none;
      background: none;
      padding: 0;
      color: var(--ed-blue);
      min-width: 100px;
      text-align: right;
    }
    .prev {
      text-align: left;
      margin-right: 80px;
    }
  }
}

.proj-guide-component{
  font-family: var(--roboto-regular);
  position: relative;
  .close-popup {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    padding: 5px 7px;
    &:hover {
      background-color: #f6f6f6;
    }
    img {
      height: 15px;
      width: 15px;
    }
  }
  .proj-guide-container{
    position: fixed;
    bottom: 10px;
    right: 20px;
    min-height: 245px;
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    box-shadow: 1px 1px 5px 0px #ccc;
    border-radius: 10px;
    background-color: white;
    padding: 40px 40px 40px;
    z-index: 999;
  }

  .proj-guide-context{
    font-size: 16px;
    position: relative;
    color: #2c3d50;
    & .tool-img {
      width: 15px;
      margin: 0 5px 0 0;
    }

    & .help-img{
      margin-left: 5px;
      margin-bottom: 4px;
    }
    .header{
      display: flex;

      .proj-guide-header{
        font-weight: bold;
      }

      .close{
        position: absolute;
        right: 25px;
        top: 10px;
        opacity: 1;

        &:hover{
          opacity: 0.4;
        }
      }
      
    }

    .proj-guide-context1{
      margin-top: 10px;
      font: var(--roboto-regular);
    }
  }
}
