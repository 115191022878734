.notification-wrapper {
  position: absolute;
  top: 70px;
  width: 400px;
  right: 120px;
  background-color: var(--white);
  border-radius: 8px;
  .modal-body {
    padding: 0 !important;
    max-height: calc(90vh - 150px);
    overflow-y: auto;
    border-radius: 8px;
  }
  & .notify-header {
    border-bottom: none;
    padding: 20px;
  }

  & .notify-tabs {
    font: var(--size-l) var(--roboto-regular);
    color: var(--black);
  }

  .tab-style {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    padding: 6px 22px !important;
    font-weight: bold;
    font-size: 17px;
    color: #182837 !important;
    letter-spacing: 2px;
  }

  .notify-user-width .tab-style {
    text-align: center;
  }
  .error-stat {
    background-color: #fbc9b5 !important;
  }
  .success-stat {
    background-color: #dceccb !important;
  }
  .time {
    color: #a9a8a8 !important;
  }
  .notification_file_icon {
    margin-right: 8px;
  }
  .filename {
    // white-space: nowrap;
    // width: 15em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .success-icon {
    color: #49c69f;
    font-size: 2.5rem;
    margin-right: 10px;
  }
  .error-icon {
    color: #f43636;
    font-size: 2.5rem;
    margin-right: 10px;
  }
  .status-image {
    background-image: url(./../../../images/process.png);
    height: 22px;
    width: 23px;
    float: right;
  }
  .success-image {
    background-image: url(./../../../images/link.png);
    height: 22px;
    width: 23px;
    float: right;
  }
  .success-map-image {
    background-image: url(./../../../images/success.png);
    height: 22px;
    width: 23px;
    float: right;
  }
  .error-image {
    background-image: url(./../../../images/error.png);
    height: 22px;
    width: 23px;
    float: right;
  }
  .notification-tabs {
    padding: 0 20px;
    .MuiTabs-indicator {
      background-color: #233b4f;
    }
    .MuiTab-wrapper {
      font: var(--size-base) var(--roboto-medium);
      color: #000;
      text-transform: none;
    }
    .notify-badge {
      color: #233b4f;
      margin-left: 10px;
      font: var(--size-xs) var(--roboto-regular);
      width: 27px;
      height: 24px;
      padding: 4px 10px;
      border-radius: 12px;
      background-color: rgba(35, 59, 79, 0.08);
    }
  }
  .or-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #868889;
    font: var(--size-xs) var(--roboto-regular);
    margin: 0;
    margin-bottom: 24px;
    padding: 0 20px;
    margin-top: 20px;
    span {
      background: transparent;
      margin: 0 20px;
      color: #868889;
    }

    &:before,
    &:after {
      background: #9c9c9c;
      height: 1px;
      content: '';
      flex: 1;
    }
  }
  .notification-container {
    display: flex;
    cursor: pointer;
    &.system-notify {
      cursor: default !important;
      &:hover {
        background: none !important;
      }
    }
    &:hover {
      background: #f8f8f8;
      .notification-link {
        color: #1382c5;
        text-decoration: underline;
      }
    }

    .circle {
      width: 9px;
      height: 9px;
      background-color: #f91;
      display: block;
      border-radius: 50%;
    }
    .notification {
      &__left {
        flex: 0 1 10%;
        margin-top: 5px;
        padding: 20px 0 20px 20px;
      }
      &__right {
        flex: 1 1 90%;
        border-bottom: 1px solid #efefef;
        padding: 20px 20px 20px 0;
      }
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          max-height: 18px;
          object-fit: contain;
          margin-right: 10px;
        }
        div {
          display: flex;
          align-items: center;
          span {
            color: #2c3d50;
            font: var(--size-base) var(--roboto-medium);
          }
        }
        span {
          font: var(--size-xs) var(--roboto-regular);
          color: #b1b3b4;
        }
      }
      &__body {
        .project-map-name {
          margin-top: 10px;
          font: var(--size-s) var(--roboto-regular);
          color: #868889;
        }
        .notification-info {
          margin-top: 5px;
          color: #2c3d50;
          font: var(--size-s) var(--roboto-regular);
          line-height: 20px;
          &:first-letter {
            text-transform: uppercase;
          }
          .document-icon {
            width: 12px;
            object-fit: contain;
            margin-right: 5px;
            margin-left: 5px;
          }
        }
        .notification-more {
          color: #233b4f;
          font: var(--size-s) var(--roboto-regular);
          font-style: italic;
          text-decoration: underline;
          cursor: pointer;
        }
        .notification-link {
          cursor: pointer;
          &:hover {
            color: #1382c5;
            text-decoration: underline;
          }
        }
        .comment-desc {
          display: block;
          margin-top: 10px;
          color: #868889;
          font: var(--size-xs) var(--roboto-regular);
        }
      }
    }
  }
  .notification-main {
    max-height: calc(90vh - 150px);
    overflow-y: auto;
    .MuiTooltip-tooltip {
      color: #fbfbfb;
      font-size: 12px;
      background-color: #8a9cad !important;
    }
    .MuiTooltip-arrow {
      color: #8a9cad;
    }
  }
  .notification-end {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 220px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    p {
      margin-top: 20px;
      color: #b1b3b4;
      font: var(--size-s) var(--roboto-regular);
      text-align: center;
    }
  }
}
