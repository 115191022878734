.snackbardialog {
  z-index: 10000 !important;
  svg {
    width: auto !important;
  }
  &.red-bckgrnd {
    .MuiPaper-root {
      background: var(--ed-red);
    }
  }
  .MuiPaper-root {
    background: var(--ed-dark_blue);
    font-size: var(--size-xs);
  }
  .MuiAlert-icon {
    align-items: center;
  }
  .MuiAlert-message {
    button {
      font: 12px var(--roboto-regular);
      vertical-align: baseline;
    }
  }
}
