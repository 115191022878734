// Plugin css

$color_1: #888;
$color_2: #aaa;
$color_3: #6a6a6a;
$color_4: #4d4d4d;
$color_5: #0c71fd;
$color_6: #b1b1b3;
$color_7: #eee;
$color_8: #ccc;
$color_9: #fff;
$color_10: #d1d1d1;
$font-family_1:
  Helvetica Neue,
  sans-serif;
$font-family_2: ArialRoundedMTBold, Arial;
$font-family_3: Arial;
$background-color_1: transparent;

.smartbanner-show.smartbanner-margin-top {
  margin-top: 80px;
}
.smartbanner-show.smartbanner-margin-bottom {
  margin-bottom: 80px;
}
.smartbanner-show {
  .smartbanner {
    display: block;
  }
}
.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: $font-family_1;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}
.smartbanner-top {
  position: absolute;
  top: 0;
}
.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}
.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}
.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-family: $font-family_2;
  font-size: 20px;
  text-align: center;
  color: $color_1;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: $background-color_1;
  -webkit-font-smoothing: subpixel-antialiased;
  &:active {
    color: $color_2;
  }
  &:hover {
    color: $color_2;
  }
}
.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
}
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  width: calc(99% - 201px);
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 700;
}
.smartbanner-wrapper {
  max-width: 110px;
  display: inline-block;
  text-align: right;
  width: 100%;
}
.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
  display: none;
}
.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:empty {
    + {
      .smartbanner-description {
        max-height: 50px;
      }
    }
  }
}
.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: $color_3;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
  &:active {
    color: $color_2;
  }
  &:hover {
    color: $color_2;
  }
}
.smartbanner-ios {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-family: $font-family_3;
    color: $color_1;
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: none;
    &:active {
      color: $color_1;
    }
    &:hover {
      color: $color_1;
    }
  }
  .smartbanner-icon {
    background-size: cover;
  }
  .smartbanner-info {
    color: $color_3;
    text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
    font-weight: 300;
  }
  .smartbanner-title {
    color: $color_4;
    font-weight: 500;
  }
  .smartbanner-button {
    padding: 0 10px;
    font-size: 15px;
    min-width: 10%;
    font-weight: 400;
    color: $color_5;
    &:active {
      background: #f2f2f2;
    }
    &:hover {
      background: #f2f2f2;
    }
  }
}
.smartbanner-android {
  background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
  line-height: 82px;
  .smartbanner-close {
    border: 0;
    max-width: 17px;
    width: 100%;
    height: 17px;
    line-height: 17px;
    margin-right: 7px;
    color: $color_6;
    text-decoration: none;
    cursor: pointer;
    &:active {
      color: $color_7;
    }
    &:hover {
      color: $color_7;
    }
  }
  .smartbanner-icon {
    background-color: $background-color_1;
    box-shadow: none;
  }
  .smartbanner-info {
    color: $color_8;
    text-shadow: 0 1px 2px #000;
  }
  .smartbanner-title {
    color: $color_9;
    font-weight: 700;
  }
  .smartbanner-button {
    min-width: 12%;
    color: $color_10;
    font-weight: 700;
    padding: 0;
    background: none;
    border-radius: 0;
    // box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
    &:active {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
  .smartbanner-button-text {
    text-align: center;
    display: block;
    padding: 0 10px;
    // background: #42b6c9;
    // background: linear-gradient(180deg, #42b6c9, #39a9bb);
    text-transform: none;
    text-shadow: none;
    box-shadow: none;
    &:active {
      background: #2ac7e1;
    }
    &:hover {
      background: #2ac7e1;
    }
  }
}
