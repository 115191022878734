// Set of Color code used in system
:root {
  --primary             : #243b4f;
  --secondary           : #8a9cad;

  --new                 : #8a9cad;
  --white               : #fff;
  --black               : #000;
  --border              : #e3eaf0;
  --bckgrnd             : #fefcfd;
  --list-hover          : #f0f3f8;
  --placeholder         : #d4d4d4;
  --active-bckgrnd      : #c6d6e6;
  --filter-bckgrnd      : #233b4f;
  --border-opacity      : #ffffff4d;
  --opacity-border      : #e3eaf080;
  --header-dropdown     : #182837;
  --dropdown-border     : #d9d7d8;
  --light-grey-blue     : #b1b3b4;
  --status-background   : #f8f8f8;
  --placeholder-grey    : #8d9cab;

  // ED colors
  --ed-red              : #f43636;
  --ed-blue             : #1382c5;
  --ed-green            : #49c69f;
  --ed-yellow           : #e8c11b;
  --ed-highlight        : #f5f5f5;
  --ed-mid_white        : #f1f1f1;
  --ed-dark_blue        : #2c3d50;
  --ed-dark_green       : #35a884;
  --ed-created_dark     : #f5efb6;
  --ed-created_light    : #fffad4;
  
  // ED Grey variations
  --ed-icons            : #8d9cb0;
  --ed-grey             : #b1b3b4;
  --ed-mid_grey         : #d8d8d8;
  --ed-dark_grey        : #868889;
  --ed-light_grey       : #cecdce;
  --ed-medium_grey      : #efefef;

  //Group head
  --badge               : #d1d1d1;
  --empty-list          : #707070;
  --group-header        : #2c3c50;
  --primary-font        : #36404b;

  //Select filters
  --checked             : #c5be19;
  --label-text          : #8ea0b2;
  --border-dark         : #dfdfdf;
  --border-light        : #e6e5e5;
  --version-bckgrnd     : #d7e3ef;
  --slect-filter-border : #e1e1e1;
  --slect-filter-bckgrnd: #f0f0f0;

  //Legacy colors
  --red                 : #f43736;
  --created             : #ffde02;
  --started             : #f43736;
  --legacy-h4           : #0c233b;
  --completed           : #4ac69f;
}
