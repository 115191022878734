.user-guide {
  max-width: 550px;
  padding: 20px 25px;
  position: relative;
  display: none;
  &_lib {
    @extend .user-guide;
    width: 360px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    > div {
      display: flex;
      align-items: center;
    }
    .module-icon {
      width: 14px;
      margin-right: 10px;
    }
    p {
      font: var(--size-base) var(--roboto-medium);
      padding: 0;
      margin: 0;
    }
  }
  &__body {
    img {
      width: 100%;
      object-fit: contain;
      margin-bottom: 25px;
      cursor: pointer;
    }
    p {
      font: var(--size-base) var(--roboto-regular);
      color: var(--ed-dark_blue);
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    &.space-btwn {
      justify-content: space-between;
    }

    &.step-up {
      flex-direction: column-reverse;
    }
    .btnFlex {
      display: flex;
      justify-content: space-between;
    }
    .noFlex {
      display: flex;
      .btn {
        margin-top: 12px;
        margin-right: 20px;
        position: absolute;
        bottom: 10px;
        right: 18px;
        font: var(--size-base) var(--roboto-medium) !important;
      }
    }

    .newGuide {
      position: relative;
      bottom: -5px;
      left: calc(100% - 60px);
    }
    .nextAndExistingGuide {
      position: relative;
      bottom: -5px;
      right: 13px;
    }
    .btn {
      font: var(--size-base) var(--roboto-medium);
      padding: 0;
      margin: 0;
      text-align: left;
    }
    .stepper {
      width: 100%;
    }
    .highlight {
      display: flex;
      margin: 20px 0;
      .screen {
        flex: 1 1 20%;
        margin-right: 10px;
        height: 4px;
        border-radius: 8px;
      }
    }
  }
  &__nextGuidefooter {
    position: relative;
    height: 43px;
    height: 50px;
    .btn {
      position: absolute;
      bottom: 12px;
      right: 0;
      font: var(--size-base) var(--roboto-medium) !important;
    }
  }
  &__main {
    position: relative;
    top: 5px;
    right: 25px;
    min-width: 300px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    padding: 0 10px;
    z-index: 100000;
    background: var(--white);
    img {
      width: 18px;
      aspect-ratio: 1;
      margin-right: 10px;
    }
    p {
      margin: 0;
      font: var(--size-base) var(--roboto-regular);
      margin-right: 10px;
    }
    .steps {
      background: var(--ed-dark_blue);
      color: var(--white);
      border-radius: 10px;
      padding: 3px 12px;
      font: var(--size-s) var(--roboto-regular);
    }
    &--header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--left {
      display: flex;
    }
    &--body {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 90%;
      margin-top: 20px;
      margin-left: 25px;
      color: var(--ed-dark_blue);
      .checkbox-container {
        margin-right: 0;
        padding-left: 30px;
      }
      p {
        margin-left: 30px;
        &:hover {
          color: var(--ed-blue);
        }
      }

      .listHeader {
        font: var(--size-base) var(--roboto-medium);
        color: var(--primary);

        &.MuiListSubheader-gutters {
          padding-left: 0 !important;
        }

        &.MuiListSubheader-sticky {
          position: relative !important;
        }
      }

      .listItem {
        font-size: 14px !important;
        white-space: pre-wrap;
        background-image: url(../../images/book-icon.svg);
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 25px;
        width: 100%;

        &:hover {
          background-image: url(../../images/book-icon-ed-blue.svg);
        }
        &.MuiListItem-button:hover {
          background-color: none !important;
          color: var(--ed-blue);
        }
      }
    }
  }
}

.free-trail-user-guide-dialog {
  z-index: 100000;
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .MuiPaper-rounded {
    border-radius: 16px;
  }
}
.user-guide-highlight {
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 1rem;
  position: relative;
  border-radius: 30px;
  border: 0;
  &--small {
    box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 0.25rem !important;
    width: 97%;
  }
  &--ml15 {
    box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 0.25rem !important;
    width: 97%;
    margin-left: 15px;
  }
  &--large {
    box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 0.25rem !important;
    padding: 25px;
    width: 100%;
    overflow-y: auto;
  }
  &--w50 {
    width: 50%;
  }
  &--xsmall {
    width: 32%;
  }
  &--removeBorder {
    border-radius: 1px;
  }
  &--removeBorderPadding {
    border-radius: 1px;
    box-shadow: none;
    overflow-y: auto;
    max-height: 440px;
  }
  &--leftMenu {
    background-color: transparent;
    box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 0.25rem !important;
    width: 97%;
    border-radius: 12px;
    padding: 10px 0;
  }
  &--repscroll {
    box-shadow: rgba(255, 255, 255, 0.9) 0 0 0 0.25rem !important;
    width: 97%;
    overflow-y: auto;
    max-height: 440px;
  }
}
.mouse-pointer {
  cursor: pointer;
}

.remove-transform {
  transform: none !important;
}

@media only screen and (max-width: 1024px) {
  .user-guide-highlight {
    &--removeBorderPadding {
      max-height: 300px;
    }
  }
}
.user-guide-header {
  position: inherit !important;
}
