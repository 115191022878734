@import './colors';
@import './variables';
@import './overrides';
@import './mixins';

body {
  overscroll-behavior: none;
}
.fsp-picker__brand {
  display: none !important;
}

.fsp-select-labels--active {
  background-image: url('./images/icon-add-files-blue.svg') !important;
}

.fsp-button--primary {
  background-color: var(--ed-blue) !important;
}
.tickets,
.audit,
.library,
.reporter,
.audit-templates,
.importTemplate,
.projects,
.copyTemplate,
.auth_bg_conatiner,
#root,
.reporter__new {
  font-family: var(--roboto-regular);
}

canvas {
  cursor: url('./images/canvas_cursor.cur'), auto;
  touch-action: none;
}

.pwd {
  .showPwd_icon {
    vertical-align: middle;
    cursor: pointer;
    display: none;
  }
  .Mui-focused {
    .showPwd_icon {
      display: flex;
    }
  }
}

.visible-hidden {
  visibility: hidden;
}

.text-center {
  text-align: center !important;
}
.mr-10 {
  margin-right: 10px;
}

.ed-blue-hr {
  border-color: var(--ed-blue);
  width: 100%;
  margin-bottom: 20px;
}

.custom-dropdown {
  &__answerType {
    z-index: 11000 !important;
  }
  .MuiMenu-paper {
    min-width: 360px !important;
    margin-left: -1px;
  }
}
.MuiFormLabel-root.Mui-focused {
  color: var(--ed-blue) !important;
}

.cursor-pointer {
  cursor: pointer;
}
.custom-switch {
  .MuiFormControlLabel-label {
    font: var(--size-s) var(--roboto-regular);
    color: #2c3c5080;
  }
  &.custom-switch-active .MuiFormControlLabel-label {
    color: var(--group-header);
  }
}
