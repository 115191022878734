.custom-tab {
  &--header {
    .MuiTab-root {
      color: var(--group-header);
      border-bottom: 0;
      font: var(--size-base) var(--roboto-regular);
      opacity: 1;
      min-width: unset;
      min-height: unset;
      text-transform: none;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0;
      }
      &.Mui-selected {
        background: var(--ed-dark_blue);
        color: var(--white);
        border-radius: 4px;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
}
