// Font variables
:root {
  --roboto-regular    : "Roboto Regular";
  --roboto-medium     : "Roboto Medium";
  --roboto-bold       : "Roboto Bold";

  --montserrat-bold   : "Montserrat Bold";
  --montserrat-regular: "Montserrat Regular";
  --montserrat-medium : "Montserrat Medium";

  // Font-Sizes
  --size-xxs          : 10px;
  --size-xs           : 12px;
  --size-s            : 14px;
  --size-base         : 16px;
  --size-l            : 18px;
  --size-xl           : 20px;
  --size-xxl          : 22px;
  --size-xxxl         : 24px;
}
