.subHeader {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__project-archive {
    margin-bottom: 15px;
    background-color: var(--slect-filter-bckgrnd);
    padding: 10px;
    display: flex;
    align-items: center;
    span {
      &:first-child {
        background-color: #b8b8b8;
        padding: 7px;
        padding-left: 10px;
        padding-right: 10px;
        color: var(--white);
        border-radius: 5px;
        margin-right: 10px;
        font-weight: bold;
      }
      color: var(--new);
      font: var(--size-base) var(--roboto-regular);
      padding-left: 200px;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    min-height: 34px;
    ul {
      margin: 0;
      margin-left: 10px;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      // border-bottom: 1px solid var(--ed-mid_white);
      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 45px;
        padding-top: 0;
        cursor: pointer;
        @media screen and (max-width: 1440px) {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }

        p {
          font: var(--size-s) var(--roboto-regular);
          color: var(--secondary);
          margin-bottom: 0;
          &:hover {
            color: #5d748b;
          }
        }
        span {
          display: flex;
          visibility: hidden;
          border-radius: 13px;
          border: 1px solid var(--ed-blue);
          color: var(--ed-blue);
          text-align: center;
          width: 35px;
          height: 20px;
          margin-left: 10px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font: var(--size-xxs) var(--roboto-regular);
          @media screen and (max-width: 1440px) {
            margin-left: 5px;
          }
        }
        &.active {
          border-bottom: 3px solid var(--filter-bckgrnd) !important;
          span {
            visibility: visible;
          }
          p {
            color: var(--primary);
          }
        }
        &.not-allowed {
          cursor: not-allowed;
        }
      }
    }
  }
}
